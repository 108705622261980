
.accordianWrapper{
    width: min(1000px, 100%);
    display: flex;
    justify-content: flex-start;
    /*padding-top: 0px;
    padding-bottom: 30px;*/
  }

.accordion {
    width : 100%;
    /*margin: 20px auto;*/
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  }
  

.accordion-header {
    margin: 0px;
    font-size: 1.2em;
    font: "Open Sans, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;";
    
}

.accordion-button {
    width: 100%;
    padding: 2%;
    /*
    text-align: middle;
    border: #3a0b97;
    border-radius: 12px;
    background: #3a0b97;
    color: white;
    */
    text-align: start;
    font-size: 0.8em;
    font-weight:bold;
    color: black;
    outline: none;
    cursor: pointer;
    transition: background 0.3s;
    border: 0;
    border-top: 1.5px solid #cbcac05f;
    border-bottom: 1.5px solid #cbcac05f;
}

.accordion-button:hover {
    background: #d1c0f4;
}

/* WATCH OUT 
the accordian-collapse is only used for accordian inside accordian !! 
*/
.accordion-collapse.show {
    display: flex;
    padding-left:13%;
    padding-right:13%;
    padding-top:5%;
    padding-bottom:3%;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; /* Add this line */
    cursor: pointer;
}

.accordion-collapse.hide {
    display: none;
}

.accordion-body.show {
    display: flex;
    padding-left:2%;
    padding-right:2%;
    padding-top:1.5%;
    padding-bottom:1.5%;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center; /* Add this line */
    cursor: pointer;
    line-height: 1.3;
    font-size:0.8em;
}

.accordion-body.hide {
    display: none;
}

.accordian-body-text {
    padding: 10px;
    font-size: 0.8em;
    font-weight: normal;
    color: black;
    text-align: start;
    line-height: 1.3;
}

  