
.info-box {
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #fff3e1; /* Light yellow background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: min(1050px, 100%);
  box-sizing: border-box;
  margin-top: 20px;
  font-size: 0.8em;
}

.info-box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f39c12;
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-right: 16px;
  flex-shrink: 0; /* Prevent the icon from shrinking */
}

.info-box-content {
  flex-grow: 1; /* Allow the content to take the remaining space */
}

.info-box-content strong {
  display: block;
  margin-bottom: 4px;
}

.info-box-content p {
  margin: 0;
}

.info-box-content a {
  color: #007bff;
  text-decoration: none;
}

.info-box-content a:hover {
  text-decoration: underline;
}

