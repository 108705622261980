/* Keyframe animation for flicker effect */
@keyframes flicker {
    0% {
      opacity: 0.5;
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.4), 0 0 15px rgba(255, 255, 255, 0.3);
    }
    20% {
      opacity: 1;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.6), 0 0 25px rgba(255, 255, 255, 0.5);
    }
    40% {
      opacity: 0.8;
      box-shadow: 0 0 20px rgba(255, 20, 147, 0.5), 0 0 35px rgba(255, 20, 147, 0.4);
    }
    60% {
      opacity: 1;
      box-shadow: 0 0 25px rgba(255, 20, 147, 0.7), 0 0 45px rgba(255, 20, 147, 0.6);
    }
    80% {
      opacity: 0.9;
      box-shadow: 0 0 30px rgba(255, 20, 147, 0.6), 0 0 50px rgba(255, 20, 147, 0.5);
    }
    100% {
      opacity: 1;
      box-shadow: 0 0 35px rgba(255, 255, 255, 0.9), 0 0 60px rgba(255, 20, 147, 0.7);
    }
  }
  
  /* Button 1 */
  .sticky-button-1 {
      position: fixed;
      bottom: 20px;
      padding: 10px 20px;
      background-color: #6c1236;
      color: white;
      margin-left: 300px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Regular shadow */
      transition: opacity 0.3s ease, visibility 0.3s ease, box-shadow 0.5s ease; /* Added box-shadow transition */
      opacity: 0; /* Initially hidden */
      visibility: hidden; /* Initially hidden */
      z-index: 10;
  }
  
  .sticky-button-1.visible {
      opacity: 1; /* Make visible */
      visibility: visible; /* Ensure visibility */
      animation: flicker 1s ease-in-out forwards; /* Flicker animation when it becomes visible */
  }
  
  .sticky-button-1:not(.visible) {
      pointer-events: none; /* Prevent interaction when hidden */
  }
  
  /* Button 2 */
  .sticky-button-2 {
      position: fixed;
      bottom: 20px;
      padding: 10px 20px;
      margin-left: 420px;
      background-color: #6c1236;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Regular shadow */
      transition: opacity 0.3s ease, visibility 0.3s ease, box-shadow 0.5s ease; /* Added box-shadow transition */
      opacity: 0; /* Initially hidden */
      visibility: hidden; /* Initially hidden */
      z-index: 10;
  }
  
  .sticky-button-2.visible {
      opacity: 1; /* Make visible */
      visibility: visible; /* Ensure visibility */
      animation: flicker 1s ease-in-out forwards; /* Flicker animation when it becomes visible */
  }
  
  .sticky-button-2:not(.visible) {
      pointer-events: none; /* Prevent interaction when hidden */
  }