/* @import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap'); */

.App {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;
  /* font: "Open Sans" */
  font: "Open Sans, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;";
  text-align: justify;
}

.App-header {
  font-size: 40px;
  margin: 30px 0;
  margin-top:100px;
  color: white;
  z-index: 1;
  text-align: center;
}

@media (max-width: 2000px){
  .App-header{
    margin: 15px 0;
    font-size: 35px;
  }
}

.blog-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.section-titles{
  box-shadow: 0.5px 0.5px 15px 1px #cacacaac;
  background-color: #6a51ae2e;
  color: #1f0075;
  width: 100%;
  text-align: center;
  font-size: 50px;
  margin-top: 50px;
  padding: 20px 0px;
  margin-bottom: 0px;
}
